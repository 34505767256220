<template>
    <v-container fluid>
      <v-card>
        <v-toolbar color="secondary" flat>
          <v-toolbar-title>Blanket Agreement</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="accent" small to="/sales/new-blanket-agreement">
            <v-icon left dark>mdi-plus</v-icon>New Blanket Agreement
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="search" append-icon="mdi-search" label="Search" single-line></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-data-table
                :loading="loading"
                loading-text="Loading... Please wait"
                :headers="headers"
                :items="laData"
                :search="search"
              >
              <!-- action template -->
              <template v-slot:item.action="{ item }">
                <v-btn icon :to="`/lease-agreement-record/${item.id}`">
                  <v-icon color="green">mdi-eye</v-icon>
                </v-btn>
              </template> 
              <!-- end of action template -->
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
</template>

<script>
export default {
  data: () => ({
    search: "",
    laData: [],
    headers: [
      { text: "Tenant Name", value: "BpName" },
      { text: "Tenant Ref No.", value: "NumAtCard" },
      { text: "Lease Months", value: "U_Months" },
      { text: "Start Date", value: "U_LeaseStartDate" },
      { text: "End Date", value: "U_LeaseEndDate" },
      { text: "extension Months", value: "U_ExtensionMonths" },
      { text: "Extension End Date", value: "EndDate" },
      { text: "Action", value: "action" },
    ]
  }),
  methods: {
    getLa() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/blanketagreement`)
        .then(res => {
          console.log(res, "rs");
          self.laData = res;
          self.loading = false;
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    }
  },
  created() {
    this.getLa();
  }
};
</script>